@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}



/* ******************** Nav Styles **************** */

.top-bar{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(32, 25, 49);
    font-size: small;
    color: red;
    height: 40px;
}

.top-bar > p {
    height: 100%;
}

@media screen and (min-width:768px) {
    .top-bar{
        font-size: large;

    }
}

.nav{
    position: sticky;
    top: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(32, 25, 49);
    color: white;
    z-index: 10;
}

.nav-button{
    font-size: larger;
}

.nav-button:hover{
    border-bottom: 3px solid red;
    padding-top: 3px;
}

.nav-button:active{
    border-bottom: 3px solid red;
    padding-top: 3px;
}





/* ******************** Services Styles **************** */

.serv-cont{
    display: flex;
    flex-direction: column;
    height: 95vh;
    background-color: rgb(250, 249, 246);
    margin: 0;
    justify-content: space-evenly;
    align-items: center;
}


.serv-item{
    background-color: rgb(250, 249, 246);
    display: flex;
    height: 20%;
    width: 70%;
    border-radius: 20px;
    box-shadow: 13px 17px 25px 3px rgba(0, 0, 0, 0.2);
    align-items: center;
}


.serv-text{
    width: 70%;
    margin-left: 5%;
}

.services-logo{
    height: 70%;
    width: auto;
    border-radius: 50%;
    background-color: white;
}

.icon{
    margin: 20px;
    transform: scale(3);
}

@media screen and (min-width:768px) {
    .serv-cont{
        flex-direction: row;
        flex-wrap: wrap;
    }

    .serv-item{
        width: 35%;
        height: 25%;
    }

    .icon{
        transform: scale(5);
        margin: 50px;
    }
}



/* ******************** About Styles **************** */

.about-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}

.about-section{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 95vh;
    width: fit;
    margin: 2%;
    box-shadow: 4px 4px 7px 1px rgba(255, 255, 255, 0.055);
    border-radius: 25px;
}


.about-section-reverse{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 150vh;
    width: fit;
    margin: 2%;
    box-shadow: 4px 4px 7px 1px rgba(255, 255, 255, 0.055);
    border-radius: 25px;
}


.about-text{
    text-align: center;
    max-width: 90%;
    max-height: 90%;
    margin: 2%;
    color: white;
    margin: 5%;
}


.about-image{
    height: 190px;
    width: auto;
    margin: 50px;
    border-radius: 25px;
}

@media screen and (min-width:768px) {
    .about-section{
        flex-direction: row;
    }

    .about-section-reverse{
        flex-direction: row-reverse;
    }

    .about-text{
        max-width: 70%;
    }

    .about-image{
        height: 400px;
        width: auto;
    }

}
/* ******************** Contact Styles **************** */

.contact-container{
    display: flex;
    flex-direction: column;
    height: 95vh;
}


.contact-row{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33%;
    justify-content: space-evenly;
}

@media screen and (min-width:768px) {
    .contact-row{
        justify-content: flex-end;
    }
}

.contact-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 200px;
    margin: 10px 0;
    padding: 5px;
    border-radius: 20px;
    font-size: small;
    box-shadow: 13px 17px 25px 3px rgba(0, 0, 0, 0.2);
}


.contact-address{
    font-size: x-small;
}

.contact-icon{
    margin: 12px;
}

.to-be-map{
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 33%;
    width: 400px;
    margin: 0;
}

@media screen and (min-width:768px) {
    .contact-item{
        margin-right: 300px;
        height: 90%;
        width: 300px;
        font-size: large;
    }

    .contact-item > p {
        font-size: medium;
    }

    .contact-row-1{
        margin-right: 300px;
    }
}
/* ******************** Footer Styles **************** */

.footer-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(32, 25, 49);
    color: white
}

/* ******************** Gallery Styles **************** */

.gallary-container{
    background-color: black;
}

.image{
    height: 70vh;
    width: auto;
    margin-left: 50%;
    transform: translate(-50%);
}

/* ******************** Modal Styles **************** */

.modal-image{
    height: 100%;
    border: 4px solid purple;
    border-radius: 20px;
}

/* ******************** Testing Styles **************** */
.test-div{
    height: 95vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

.blue{
    background-color: blue;
}

.orange{
    background-color: orange;
}

.yellow{
    background-color: yellow;
    color: black
}

.red{
    background-color: red;
}
.inline{
    display: flex;
    justify-content: center;
    align-items: center;
}
